<script>
import Vue from 'vue';
import moment from 'moment';
import { mapGetters } from 'vuex';
var templateRenderFns = [];

export default {
  name: 'WisdomShow',
  render (h) {
    return h('div', [
      (this.template ? this.template() : '')
    ]);
  },
  staticRenderFns: templateRenderFns,
  mounted () {
    var compiled_template = Vue.compile(this.build_form(this.data, this.group_parameters));
    this.template = compiled_template.render;
    templateRenderFns.length = 0;
    for (var i in compiled_template.staticRenderFns) {
      templateRenderFns.push(compiled_template.staticRenderFns[i]);
    }
  },
  computed: mapGetters({
    lang: 'lang'
  }),
  data () {
    return {
      template: null,
      select_types: ['select', 'checkbox', 'object_list']
    };
  },
  methods: {
    build_form: function (data, group_parameters) {
      let html = '';
      for (let i in group_parameters.param_order) {
        let param = group_parameters.param_order[i];
        if (group_parameters.parameters[param].type === 'create_new_param' && group_parameters.parameters[param].create_param_list) {
          html += this.create_new_param(data, group_parameters, param);
        } else if (group_parameters.parameters[param].type !== 'object_list' && data[param]) {
          html += this.create_param(data, group_parameters, param);
        } else if (group_parameters.parameters[param].type !== 'object_list_options') {
          html += this.create_object_list_options(data[param], group_parameters.parameters[param]);
        }
      }
      return '<form>' + html + '</form>';
    },
    create_param: function (data, group_parameters, param) {
      let html = '';
      if (this.view_mode === 'table') {
        if (group_parameters.parameters[param].type === 'date') {
          html += '<div class="row">';
          html += '<div class="col-3">' + group_parameters.parameters[param].label + '</div>';
          html += '<div class="col-9">' + this.DateFormat(data[param]) + '</div>';
          html += '</div>';
        } else if (this.select_types.indexOf(group_parameters.parameters[param].type) !== -1 && data[param].label) {
          html += '<div class="row">';
          html += '<div class="col-3">' + group_parameters.parameters[param].label + '</div>';
          html += '<div class="col-9">' + data[param].label + '</div>';
          html += '</div>';
        } else if (['integer', 'textarea', 'text'].indexOf(group_parameters.parameters[param].type) !== -1) {
          html += '<div class="row">';
          html += '<div class="col-3">' + group_parameters.parameters[param].label + '</div>';
          html += '<div class="col-9">' + data[param] + '</div>';
          html += '</div>';
        }
      }
      return html;
    },
    create_object_list_options: function (data, mother_parameter_data) {
      let html = '';
      for (let i in data) {
        if (mother_parameter_data.object_type === 'object') {
          html += '<strong style="color: red;">' + data[i].name.label + '</strong>';
        } else if (mother_parameter_data.object_type === 'list') {
          html += '<strong style="color: red;">' + data[i].name.label + '</strong>';
          if (data[i].count) {
            html += 'Sayı = ' + data[i].count;
          }
          html += '<div>';
          for (let ls in data[i].list) {
            html += '<strong style="color: red;">' + (ls + 1).toString() + data[i].name.label + ' </strong>';
            html += this.build_form(data[i].list[ls], mother_parameter_data);
          }
          html += '</div>';
        }
      }
      return html;
    },
    create_new_param: function (data, group_parameters, param) {
      let html = '';
      for (let c in group_parameters.parameters[param].create_param_list) {
        let new_param = group_parameters.parameters[param].create_param_list[c];
        for (let op in group_parameters.parameters[param].options) {
          let option = group_parameters.parameters[param].options[op];
          let data_param = option.value + '__' + new_param;
          if (data[data_param]) {
            html += '<div class="row">';
            html += '<div class="col-3">' + option.label + ' ' + group_parameters.parameters[new_param].label + '</div>';
            html += '<div class="col-9">' + data[data_param] + '</div>';
            html += '</div>';
          }
        }
      }
      return html;
    },
    calculate_col: function (x) {
      if (x === 'parameter') {
        if (this.relation === 'mother') {
          return 'col-2';
        } else {
          return 'col-4';
        }
      } else {
        if (this.relation === 'mother') {
          return 'col-10';
        } else {
          return 'col-8';
        }
      }
    },
    DateFormat: function (date) {
      if (date) {
        return moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY');
      } else {
        return '';
      }
    }
  },
  watch: {},
  components: {
  },
  props: {
    data: {
      type: Object,
      required: false
    },
    group_parameters: {
      type: Object,
      required: false
    },
    view_mode: {
      type: String,
      required: true,
      default: 'table'
    },
    relation: {
      type: String,
      required: true,
      default: 'mother'
    }
  }
};

</script>

<style>
</style>
