<template>
  <div v-html="result_html">
  </div>
</template>

<script>
// import Vue from 'vue';
import moment from 'moment';
import { mapGetters } from 'vuex';
// var templateRenderFns = [];

export default {
  name: 'WisdomShow',
  /*  render (h) {
      return h('div', [
        (this.template ? this.template() : '')
      ]);
    },
    staticRenderFns: templateRenderFns, */
  props: {
    data: {
      type: Object,
      required: false,
      default: () => {}
    },
    group_parameters: {
      type: Object,
      required: false
    },
    view_mode: {
      type: String,
      required: true,
      default: 'table'
    },
    relation: {
      type: String,
      required: true,
      default: 'mother'
    },
    param_group: {
      type: String,
      required: true
    },
    change: {
      type: String,
      required: true,
      default: '0'
    },
    brief_mode: {
      type: Number,
      required: false
    },
    user_brief_list: {
      type: Array,
      required: false
    }
  },
  components: {},
  mounted () {
    /*    let compiled_template = Vue.compile(this.build_form(this.data, this.group_parameters));
        this.template = compiled_template.render;
        templateRenderFns.length = 0;
        console.log(templateRenderFns);
        console.log(compiled_template.staticRenderFns.length);
        for (var i in compiled_template.staticRenderFns) {
          templateRenderFns.push(compiled_template.staticRenderFns[i]);
        } */
    if (this.brief_mode) {
      this.user = JSON.parse(localStorage.getItem('user'));

    }
    this.result_html = this.build_form(this.data, this.group_parameters);
  },
  computed: mapGetters({
    lang: 'lang'
  }),
  data () {
    return {
      user: {},
      level: 1,
      result_html: '',
      template: null,
      select_types: ['select', 'object_list', 'radiogroup'],
      watch_data: { 'index_list': [] }
    };
  },
  methods: {
    build_form: function (data, group_parameters) {
      let html = '';
      this.watch_data = { 'index_list': [] };
      this.watch_data.index_list.push(this.param_group);
      if (this.view_mode === 'wdm_specific') {
        let polestar_list = [];
        let table_list = [];
        let sentence_list = [];

        for (let i in group_parameters.param_order) {
          let param = group_parameters.param_order[i];
          if (!group_parameters.parameters[param].wdm_specific || (group_parameters.parameters[param].wdm_specific && (group_parameters.parameters[param].wdm_specific === undefined || group_parameters.parameters[param].wdm_specific === 'table'))) {
            table_list.push(param);
          } else if (group_parameters.parameters[param].wdm_specific && group_parameters.parameters[param].wdm_specific === 'sentence') {
            sentence_list.push(param);
          } else if (group_parameters.parameters[param].wdm_specific && group_parameters.parameters[param].wdm_specific === 'polestar') {
            polestar_list.push(param);
          }
        }

        if (table_list.length > 0 || sentence_list.length > 0) {

          /* Every item of data includes:::::::
          data[i] = {
            'drug': {'val': {'value': 'zoledronic', 'Label': 'Zoledronik acid'}},
            .....
          }
          */
          let x_table = '';
          let x_sentence = '';
          if (table_list.length > 0) {
            x_table = this.main_param_func(data, group_parameters, '', table_list, 'table');
          }
          if (sentence_list.length > 0) {
            let x_sentence = this.main_param_func(data, group_parameters, '', sentence_list, 'sentence');
          }
          html += x_table;
          html += x_sentence;
        }

        if (polestar_list.length > 0) {
          html += this.main_param_func(data, group_parameters, 0, polestar_list, 'polestar');
          for (let i in data) {
            html += this.main_param_func(data, group_parameters, '', polestar_list, 'polestar');
          }
          html += '</tr></table>';
        }
      } else {
        html += this.main_param_func(data, group_parameters);
      }
      return '<form>' + html + '</form>';
    },
    main_param_func: function (data, group_parameters, list_no = '', calculate_list = [], calculate_list_type = '') {
      let html = '';
      if (this.view_mode === 'polestar' || calculate_list_type === 'polestar') {
        if (list_no === 0) {
          html += '<table class="wdm_specific_polestar_table">';
        }
        html += '<tr class="wdm_specific_polestar_tr">';
      }
      for (let i in group_parameters.param_order) {
        let param = group_parameters.param_order[i];
        if (parseInt(i) === 0) {
          this.watch_data.index_list.push(param);
        } else {
          this.watch_data.index_list[this.watch_data.index_list.length - 1] = param;
        }
        if (this.brief_mode && this.user_brief_list && this.user_brief_list.length > 0) {
          let det = false;
          for (let b in this.user_brief_list) {
            if (this.watch_data.index_list.join() === this.user_brief_list[b].join()) {
              det = true;
              break;
            }
          }
          if (!det) {
            continue;
          }
        }
        // console.log(this.watch_data.index_list);
        if (group_parameters.parameters[param].page_not_show) {
          continue;
        }
        if (group_parameters.parameters[param].type === 'create_new_param' && group_parameters.parameters[param].create_param_list) {
          html += this.create_new_param(data, group_parameters, param);
        } else if (group_parameters.parameters[param].type === 'object_list_options' && typeof data[param] === 'object' && Object.keys(data[param]).length > 0) {
          html += this.create_object_list_options(data[param], group_parameters.parameters[param], group_parameters.parameters[param].name.label);
        } else if (group_parameters.parameters[param].type === 'list_parameter' && data[param] && data[param].list && data[param].list.length > 0) {
          html += this.list_parameter(data[param].list, group_parameters.parameters[param], group_parameters.parameters[param].name.label);
        } else if (group_parameters.parameters[param].type !== 'object_list' && data[param] && data[param]['val'] && group_parameters.parameters[param].type !== 'object_list_options') {
          // console.log(param);
          html += this.create_param(data, group_parameters, param, list_no, calculate_list, calculate_list_type);
        }
      }
      return html;
    },
    f_getOptionLabel: function (val, options) {
      // Now we are controlling options. Because we dont have options some times which options comes with functions.
      if (options && options.length > 0) {
        for (let i in options) {
          if (options[i].value === val.value) {
            return options[i].label;
          }
        }
      }
      if (val.label) {
        return val.label;
      }
      return '';
    },
    create_param: function (data, group_parameters, param, list_no = '', calculate_list = [], calculate_list_type = '') {
      let html = '';
      if (this.view_mode === 'table' || (calculate_list_type === 'table' && calculate_list.indexOf(param) !== -1)) {
        if (group_parameters.parameters[param].type === 'date') {
          html += '<div class="row wisdom-show-row">';
          html += '<div class="col-sm-6">' + '<span class="' + this.calculate_level_style('param') + '"></span>' + '<span class="level-' + this.level + '-param-caption">' + group_parameters.parameters[param].name.label + ': </span></div>';
          html += '<div class="col-sm-6">' + '<span class="level-' + this.level + '-option">' + moment(data[param]['val']).format("DD/MM/YYYY") + '</span></div>';
          html += '</div>';
        } else if (group_parameters.parameters[param].type === 'datetime') {
          html += '<div class="row wisdom-show-row">';
          html += '<div class="col-sm-6">' + '<span class="' + this.calculate_level_style('param') + '"></span>' + '<span class="level-' + this.level + '-param-caption">' + group_parameters.parameters[param].name.label + ': </span></div>';
          html += '<div class="col-sm-6">' + '<span class="level-' + this.level + '-option">' + moment(data[param]['val']).format("DD/MM/YYYY HH:mm") + '</span></div>';
          html += '</div>';
        } else if (this.select_types.indexOf(group_parameters.parameters[param].type) !== -1 && data[param]['val'].label) {
          html += '<div class="row wisdom-show-row">';
          html += '<div class="col-sm-6">' + '<span class="' + this.calculate_level_style('param') + '"></span>' + '<span class="level-' + this.level + '-param-caption">' + group_parameters.parameters[param].name.label + ': </span></div>';
          html += '<div class="col-sm-6">' + '<span class="level-' + this.level + '-option">' + this.f_getOptionLabel(data[param]['val'], group_parameters.parameters[param].options) + '</span></div>';
          html += '</div>';
        } else if (['integer', 'float', 'textarea', 'text', 'time', 'plain_text'].indexOf(group_parameters.parameters[param].type) !== -1) {
          html += '<div class="row wisdom-show-row">';
          html += '<div class="col-sm-6">' + '<span class="' + this.calculate_level_style('param') + '"></span>' + '<span class="level-' + this.level + '-param-caption">' + group_parameters.parameters[param].name.label + ': </span></div>';
          let anormal_value_class = this.f_calculateAnormalValueClass(data[param]);
          let lab_unit = '';
          if (data[param].unit_name !== undefined) {
            lab_unit = data[param].unit_name;
          }
          let lab_range_html = this.f_createLabRangeHtml(data[param]);
          html += '<div class="col-sm-6">' + '<span class="level-' + this.level + '-option ' + anormal_value_class + '">' + data[param]['val'] + ' ' + lab_unit + '</span>' + lab_range_html + '</div>';
          html += '</div>';
        } else if (['html_editor'].indexOf(group_parameters.parameters[param].type) !== -1 && data[param]['val'].length > 0) {
          html += '<div class="row wisdom-show-row">';
          html += '<div class="col-sm-6">' + '<span class="' + this.calculate_level_style('param') + '"></span>' + '<span class="level-' + this.level + '-param-caption">' + group_parameters.parameters[param].name.label + ': </span></div>';
          let anormal_value_class = this.f_calculateAnormalValueClass(data[param]);
          let lab_unit = '';
          if (data[param].unit_name !== undefined) {
            lab_unit = data[param].unit_name;
          }
          let lab_range_html = this.f_createLabRangeHtml(data[param]);
          html += '<div class="col-sm-6">' + '<span class="level-' + this.level + '-option ' + anormal_value_class + '">' + data[param]['val'] + ' ' + lab_unit + '</span>' + lab_range_html + '</div>';
          html += '</div>';
        } else if (['checkbox'].indexOf(group_parameters.parameters[param].type) !== -1 && data[param]['val'].length > 0) {
          html += '<div class="row wisdom-show-row">';
          html += '<div class="col-sm-6">' + '<span class="' + this.calculate_level_style('param') + '"></span>' + '<span class="level-' + this.level + '-param-caption">' + group_parameters.parameters[param].name.label + ': </span></div>';
          html += '<div class="col-sm-6">' + '<span class="level-' + this.level + '-option">' + this.ArrayListParamData(data[param]['val'], group_parameters.parameters[param]) + '</span></div>';
          html += '</div>';
        }
      } else if (this.view_mode === 'sentence' || (calculate_list_type === 'sentence' && calculate_list.indexOf(param) !== -1)) {
        if (group_parameters.parameters[param].type === 'date') {
          html += '<strong>' + group_parameters.parameters[param].name.label + ': </strong>';
          html += '<span>' + moment(data[param]['val']).format("DD/MM/YYYY") + ' </span>';
        } else if (group_parameters.parameters[param].type === 'datetime') {
          html += '<strong>' + group_parameters.parameters[param].name.label + ': </strong>';
          html += '<span>' + moment(data[param]['val']).format("DD/MM/YYYY HH:mm") + ' </span>';
        } else if (this.select_types.indexOf(group_parameters.parameters[param].type) !== -1) {
          html += '<strong>' + group_parameters.parameters[param].name.label + ': </strong>';
          html += '<span>' + this.f_getOptionLabel(data[param]['val'], group_parameters.parameters[param].options) + ' </span>';
        } else if (['integer', 'float', 'textarea', 'text', 'time'].indexOf(group_parameters.parameters[param].type) !== -1) {
          html += '<strong>' + group_parameters.parameters[param].name.label + ': </strong>';
          html += '<span>' + data[param]['val'] + ' </span>';
        } else if (['html_editor'].indexOf(group_parameters.parameters[param].type) !== -1) {
          html += '<strong>' + group_parameters.parameters[param].name.label + ': </strong>';
          html += '<span>' + data[param]['val'] + ' </span>';
        } else if (['checkbox'].indexOf(group_parameters.parameters[param].type) !== -1) {
          html += '<strong>' + group_parameters.parameters[param].name.label + ': </strong>';
          html += '<span>' + this.ArrayListParamData(data[param]['val'], group_parameters.parameters[param]) + ' </span>';
        }
      } else if (this.view_mode === 'polestar' || (calculate_list_type === 'polestar' && calculate_list.indexOf(param) !== -1)) {
        if (list_no === 0) {
          html += '<th class="wdm_specific_polestar_th">';
          html += '<strong>' + group_parameters.parameters[param].name.label + ': </strong>';
          html += '</th>';
        } else {
          html += '<td class="wdm_specific_polestar_td">';
          if (group_parameters.parameters[param].type === 'date') {
            html += '<span>' + moment(data[param]['val']).format("DD/MM/YYYY") + ' </span>';
          } else if (group_parameters.parameters[param].type === 'datetime') {
            html += '<span>' + moment(data[param]['val']).format("DD/MM/YYYY HH:mm") + ' </span>';
          } else if (this.select_types.indexOf(group_parameters.parameters[param].type) !== -1) {
            html += '<span>' + this.f_getOptionLabel(data[param]['val'], group_parameters.parameters[param].options) + ' </span>';
          } else if (['integer', 'float', 'textarea', 'text', 'time'].indexOf(group_parameters.parameters[param].type) !== -1) {
            html += '<span>' + data[param]['val'] + ' </span>';
          } else if (['html_editor'].indexOf(group_parameters.parameters[param].type) !== -1) {
            html += '<span>' + data[param]['val'] + ' </span>';
          } else if (['checkbox'].indexOf(group_parameters.parameters[param].type) !== -1) {
            html += '<span>' + this.ArrayListParamData(data[param]['val'], group_parameters.parameters[param]) + ' </span>';
          }
          html += '</td>';

        }
      }
      return html;
    },
    f_createLabRangeHtml: function (data_param) {
      let html = '';
      if (data_param.val) {
        if (data_param.lb !== undefined) {
          if (!html) {
            html = '<span class="pull-right" style="margin-left: 20px;">';
          }
          html += '[' + data_param.lb + ' - ';
        }
        if (data_param.ub !== undefined) {
          if (!html) {
            html = '<span class="pull-right">';
          }
          html += data_param.ub;
        }
        if (html) {
          html += ' ]</span>';
          return html;
        } else {
          return '';
        }
      }
    },
    f_calculateAnormalValueClass: function (data_param) {
      if (data_param.val) {
        if (data_param.clb !== undefined && data_param.clb !== '' && data_param.clb !== null) {
          if (data_param.val < data_param.clb) {
            return 'clb-value';
          }
        }
        if (data_param.cub !== undefined && data_param.cub !== '' && data_param.cub !== null) {
          if (data_param.val > data_param.cub) {
            return 'cub-value';
          }
        }
        if (data_param.ub !== undefined && data_param.ub !== '' && data_param.ub !== null) {
          if (data_param.val > data_param.ub) {
            return 'ub-value';
          }
        }
        if (data_param.lb !== undefined && data_param.lb !== '' && data_param.lb !== null) {
          if (data_param.val < data_param.lb) {
            return 'lb-value';
          }
        }
        return '';
      }
    },
    ArrayListParamData: function (arrayData, options) {
      let str = '';
      for (let i in arrayData) {
        if (options && options.length > 0) {
          for (let o in options) {
            if (options[o].value === arrayData[i].value) {
              str += '* ' + options[o].label;
              break;
            }
          }
        } else if (arrayData[i].label) {
          str += arrayData[i].label;
        }
        if (parseInt(i) !== arrayData.length - 1) {
          if (this.view_mode === 'table') {
            str += ',\n';
          } else if (this.view_mode === 'sentence') {
            str += ', ';
          }
        }
      }
      return str;
    },
    list_parameter: function (list_data, mother_parameter_data, list_caption) {
      let html = '';
      this.level += 1;
      if (this.view_mode === 'table') {
        for (let i in list_data) {
          html += '<div class="row wisdom-show-row">';
          html += '<div class="col-sm-6">' + '<span class="' + this.calculate_level_style('list') + '"></span>' + '<span class="level-' + this.level + '-list-caption">' + list_caption + ' - ' + (parseInt(i) + 1).toString() + '</span></div>';
          html += '<div class="col-sm-6">' + '' + '</div>';
          html += '</div>';
          html += this.main_param_func(list_data[i], mother_parameter_data, i);
        }
      } else if (this.view_mode === 'sentence') {
        html += '<strong>' + list_caption + ': </strong>';
        for (let i in list_data) {
          html += '<strong>' + (parseInt(i) + 1).toString() + ' -) </strong>';
          html += this.main_param_func(list_data[i], mother_parameter_data, i);
        }
      } else if (this.view_mode === 'polestar') {
        html += '<div class="row wisdom-show-row">';
        html += '<div class="col-sm-6">' + '<span class="' + this.calculate_level_style('list') + '"></span>' + '<span class="level-' + this.level + '-list-caption">' + list_caption + ' - ' + (parseInt(0) + 1).toString() + '</span></div>';
        html += '<div class="col-sm-6">' + '' + '</div>';
        html += '</div>';
        html += this.main_param_func(list_data[0], mother_parameter_data, 0);

        for (let i in list_data) {
          html += this.main_param_func(list_data[i], mother_parameter_data, i);
        }
        html += '</tr></table>';
      } else if (this.view_mode === 'wdm_specific') {
        let polestar_list = [];
        let table_list = [];
        let sentence_list = [];

        for (let i in mother_parameter_data.param_order) {
          let param = mother_parameter_data.param_order[i];
          if (!mother_parameter_data.parameters[param].wdm_specific || (mother_parameter_data.parameters[param].wdm_specific && (mother_parameter_data.parameters[param].wdm_specific === undefined || mother_parameter_data.parameters[param].wdm_specific === 'table'))) {
            table_list.push(param);
          } else if (mother_parameter_data.parameters[param].wdm_specific && mother_parameter_data.parameters[param].wdm_specific === 'sentence') {
            sentence_list.push(param);
          } else if (mother_parameter_data.parameters[param].wdm_specific && mother_parameter_data.parameters[param].wdm_specific === 'polestar') {
            polestar_list.push(param);
          }
        }

        // let is_polestar_sent = false;
        if (table_list.length > 0 || sentence_list.length > 0) {
          for (let i in list_data) {
            /* Every item of list_data includes:::::::
            list_Data[i] = {
              'drug': {'val': {'value': 'zoledronic', 'Label': 'Zoledronik acid'}},
              .....
            }
            */
            let x_table = '';
            let x_sentence = '';
            if (table_list.length > 0) {
              x_table = this.main_param_func(list_data[i], mother_parameter_data, i, table_list, 'table');
            }
            if (sentence_list.length > 0) {
              let x_sentence = this.main_param_func(list_data[i], mother_parameter_data, i, sentence_list, 'sentence');
            }
            if (x_table !== '' || x_sentence !== '') {
              html += '<div class="row wisdom-show-row">';
              html += '<div class="col-sm-6">' + '<span class="' + this.calculate_level_style('list') + '"></span>' + '<span class="level-' + this.level + '-list-caption">' + list_caption + ' - ' + (parseInt(i) + 1).toString() + '</span></div>';
              html += '<div class="col-sm-6">' + '' + '</div>';
              html += '</div>';
            }
            html += x_table;
            html += x_sentence;
          }
        }

        if (polestar_list.length > 0) {
          html += this.main_param_func(list_data[0], mother_parameter_data, 0, polestar_list, 'polestar');
          for (let i in list_data) {
            html += this.main_param_func(list_data[i], mother_parameter_data, i, polestar_list, 'polestar');
          }
          html += '</tr></table>';
        }
      }
      this.level -= 1;
      return html;
    },
    create_object_list_options: function (data, mother_parameter_data, list_caption) {
      let html = '';
      this.level += 1;
      // console.log('level ::::::', this.level);
      if (this.view_mode === 'table') {
        html += '<div class="row wisdom-show-row">';
        html += '<div class="col-sm-6">' + '<span class="' + this.calculate_level_style('list') + '"></span>' + '<span class="level-' + this.level + '-list-caption">' + list_caption + '</span></div>';
        html += '<div class="col-sm-6"></div>';
        html += '</div>';
        this.level += 1;
        for (let i in data) {
          if (mother_parameter_data.object_type === 'object') {
            html += '<div class="row wisdom-show-row">';
            html += '<div class="col-sm-6">' + '<span class="' + this.calculate_level_style('detail') + '"></span>' + '<span class="level-' + this.level + '-detail-caption">' + data[i].name.label + ' </span></div>';
            html += '<div class="col-sm-6"></div>';
            html += '</div>';
            if (data[i].detail) {
              html += this.main_param_func(data[i].detail, mother_parameter_data);
            }
          } else if (mother_parameter_data.object_type === 'list') {
            html += '<div class="row wisdom-show-row">';
            html += '<div class="col-sm-12"><strong>' + data[i].name.label + '</strong>';
            if (data[i].count) {
              html += '( adet = ' + data[i].count + ' )';
            }
            html += '</div>';
            html += '</div>';
            if (data[i].list.length > 0) {
              html += this.list_parameter(data[i].list, mother_parameter_data, data[i].name.label);
            }
          }
        }
        this.level -= 1;
      } else if (this.view_mode === 'sentence') {
        html += '<strong>' + list_caption + ': </strong>';
        for (let i in data) {
          if (mother_parameter_data.object_type === 'object') {
            html += data[i].name.label + ', ';
            if (data[i].detail) {
              html += this.main_param_func(data[i].detail, mother_parameter_data);
            }
          } else if (mother_parameter_data.object_type === 'list') {
            html += '<strong>' + data[i].name.label + ': </strong>';
            if (data[i].count) {
              html += '( adet = ' + data[i].count + ' )';
            }
            if (data[i].list.length > 0) {
              html += this.list_parameter(data[i].list, mother_parameter_data, data[i].name.label);
            }
          }
        }
      }
      this.level -= 1;
      return html;
    },
    calculate_level_style: function (type) {
      return 'level-' + this.level + '-' + type;
    },
    create_new_param: function (data, group_parameters, param) {
      let html = '';
      for (let c in group_parameters.parameters[param].create_param_list) {
        let new_param = group_parameters.parameters[param].create_param_list[c];
        for (let op in group_parameters.parameters[param].options) {
          let option = group_parameters.parameters[param].options[op];
          let data_param = option.value + '__' + new_param;
          if (data[data_param]) {
            if (this.view_mode === 'table') {
              html += '<div class="row">';
              html += '<div class="col-sm-6"><strong>' + option.label + ' - ' + group_parameters.parameters[new_param].name.label + '</strong></div>';
              if (data[data_param].label) {
                html += '<div class="col-sm-6">' + data[data_param].label + '</div>';
              } else {
                html += '<div class="col-sm-6">' + data[data_param] + '</div>';
              }
              html += '</div>';
            } else if (this.view_mode === 'sentence') {
              html += '<strong>' + option.label + ' - ' + group_parameters.parameters[new_param].name.label + ': </strong>';
              if (data[data_param].label) {
                html += '<span>' + data[data_param].label + ' </span>';
              } else {
                html += '<span>' + data[data_param] + ' </span>';
              }
            }
          }
        }
      }
      return html;
    },
    calculate_col: function (x) {
      if (x === 'parameter') {
        if (this.relation === 'mother') {
          return 'col-sm-2';
        } else {
          return 'col-sm-6';
        }
      } else {
        if (this.relation === 'mother') {
          return 'col-sm-10';
        } else {
          return 'col-sm-6';
        }
      }
    },
    DateFormat: function (date) {
      if (date) {
        return moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY');
      } else {
        return '';
      }
    }
  },
  watch: {
    view_mode: function () {
      this.result_html = this.build_form(this.data, this.group_parameters);
      this.$forceUpdate();
    },
    change: function () {
      this.result_html = this.build_form(this.data, this.group_parameters);
      this.$forceUpdate();
    }
  }
};

</script>

<style>
.wisdom-show-row {
  margin: 0px;
  border-bottom: solid 0.5px #d9d4d4;
}

.wisdom-show-row:hover {
  margin: 0px;
  border-bottom: solid 0.5px #d9d4d4;
  background-color: rgba(142, 243, 124, 0.65);
}

.level-1-param {
  padding-left: 10px;
  margin-left: 10px;
}

.level-2-param {
  padding-left: 20px;
  border-left: dotted 1px #20a8d8;
  border-bottom: dotted 1px #20a8d8;
  margin-left: 30px;
}

.level-3-param {
  padding-left: 20px;
  border-left: dotted 1px green;
  border-bottom: dotted 1px green;
  margin-left: 60px;
}

.level-4-param {
  padding-left: 20px;
  border-left: dotted 1px #c05353;
  border-bottom: dotted 1px #c05353;
  margin-left: 90px;
}

.level-2-list {
  padding-left: 10px;
  margin-left: 10px;
}

.level-3-list {
  padding-left: 20px;
  border-left: dotted 1px #20a8d8;
  border-bottom: dotted 1px #20a8d8;
  margin-left: 30px;
}

.level-4-list {
  padding-left: 20px;
  border-left: dotted 1px green;
  border-bottom: dotted 1px green;
  margin-left: 60px;
}

.level-1-detail {
  padding-left: 10px;
  border-left: 1px solid black;
  border-bottom: solid 1px blue;
  margin-left: 20px;
}

.level-2-detail {
  padding-left: 20px;
  border-left: 1px solid black;
  border-bottom: solid 1px blue;
  margin-left: 20px;
}

.level-3-detail {
  padding-left: 20px;
  border-left: dotted 1px #20a8d8;
  border-bottom: dotted 1px #20a8d8;
  margin-left: 30px;
}

.level-4-detail {
  padding-left: 20px;
  border-left: dotted 1px green;
  border-bottom: dotted 1px green;
  margin-left: 60px;
}

.level-5-detail {
  padding-left: 20px;
  border-left: dotted 1px #c05353;
  border-bottom: dotted 1px #c05353;
  margin-left: 90px;
}

.level-1-param-caption {
  color: black;
}

.level-2-param-caption {
  color: #20a8d8;
}

.level-3-param-caption {
  color: green;
}

.level-4-param-caption {
  color: #c05353;
}

.level-3-list-caption {
  color: #20a8d8;
}

.level-4-list-caption {
  color: green;
}

.level-3-detail-caption {
  color: #20a8d8;
}

.level-4-detail-caption {
  color: green;
}

.level-5-detail-caption {
  color: #c05353;
}

.level-1-option {
  color: black;
  white-space: pre-line;
}

.level-2-option {
  color: #20a8d8;
  white-space: pre-line;
}

.level-3-option {
  color: green;
  white-space: pre-line;
}

.level-4-option {
  color: #c05353;
  white-space: pre-line;
}

.clb-value {
  color: red;
  font-weight: bold;
}

.cub-value {
  color: red;
  font-weight: bold;
}

.lb-value {
  color: red;
  font-weight: bold;
}

.ub-value {
  color: red;
  font-weight: bold;
}

.wdm_specific_polestar_table {
  padding-left: 35px;
  margin-left: 35px;
  border: solid 1px #c3d8d8;
  width: calc(100% - 35px);
}

.wdm_specific_polestar_tr {
  border: solid 1px #c3d8d8;
}

.wdm_specific_polestar_td {
  border: solid 1px #c3d8d8;
}

.wdm_specific_polestar_th {
  border: solid 1px #c3d8d8;
}

</style>

