class InputControl {
  static input_filter = function(event, type, my_list = []) {
    var key_list = [];
    if (my_list.length === 0) {
      if (['period', 'delay'].indexOf(type) !== -1) {
        key_list = ['ArrowUp', 'ArrowDown', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      }
    } else {
      key_list = my_list;
    }
    if (key_list.indexOf(event.key) === -1) {
      event.preventDefault();
    }
  };
};


export {
  InputControl
};